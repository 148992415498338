import React from 'react'
import './base.css'
import Container from './container'
import Navigation from './navigation'
import Footer from './footer/footer'
import { IntlProvider } from 'react-intl'
import {getCurrentLangKey, getLangs, getUrlForLang} from 'ptz-i18n'
import {SocialMenu} from "./social-menu";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from "react-helmet";

class Template extends React.Component {

  render() {
    const {location, children} = this.props
    const url = location.pathname;
    const langs = ['en', 'ar']
    const defaultLangKey = 'en'
    const langKey = getCurrentLangKey(langs, defaultLangKey, url);
    const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/');
    const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({
      ...item,
      link: item.link.replace(`/${defaultLangKey}/`, '/')
    }));


    return (
      <IntlProvider
        locale={langKey}
        messages={"message"}
      >
        <Helmet
          htmlAttributes={{
            dir: langKey === 'ar' ? 'rtl' : 'lrt',
            lang: langKey,
          }}
        />
        <SocialMenu />
        <Container>
          <Navigation location={location} langs={langsMenu} currentLang={langKey}/>
          <main>
            {children}
          </main>
          <Footer lang={langKey}/>
        </Container>
      </IntlProvider>
    )
  }
}

export default Template
