import React from 'react'
import {Link} from "gatsby";
import {FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon, YoutubeIcon} from "../icons";
import * as styles from './social.menu.module.css'

export const SocialMenu = () => {
  return (
    <ul className={styles.socialMenu}>
      <li>
        <a href={'https://www.facebook.com/search/top?q=Homebest'} target={'_blank'}><FacebookIcon /></a>
      </li>
      <li>
        <a href={'https://twitter.com/Homebest8'} target={'_blank'}><TwitterIcon /></a>
      </li>
      <li>
        <a href={'https://www.youtube.com/channel/UCWhxWHkBlqZKBn35HqwY9sQ'} target={'_blank'}><YoutubeIcon /></a>
      </li>
      <li>
        <a href={'https://www.instagram.com/homebest_ningbo/'} target={'_blank'}><InstagramIcon /></a>
      </li>
      <li>
        <a href={'https://www.linkedin.com/company/homebest/mycompany'} target={'_blank'}><LinkedInIcon /></a>
      </li>
    </ul>
  )
}