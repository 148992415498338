import {getCurrentLangKey} from "ptz-i18n";

export const useLang = () => {

  if (typeof window !== 'undefined') {
    const url = window.location.pathname
    const langs = ['en', 'ar']
    const defaultLangKey = 'en'
    const lang = getCurrentLangKey(langs, defaultLangKey, url);

    return lang === 'en' ? '' : `/${lang}`
  }

  return ''
}